import * as React from 'react'

import { Link } from 'gatsby'

interface IPropTypes {
  title: string
  slug: string
  description: string
  tags?: Array<string>
}

const FeaturedBlogCard = ({ title, slug, description, tags }: IPropTypes) => {
  return (
    <div className="col-span-12 py-2 transition ease-in-out hover:scale-[1.01]">
      <Link to={`/blog/${slug}`}>
        <h3 className="font-bold text-xl text-primary">{title}</h3>
        <p className="text-primary opacity-60">{description}</p>
      </Link>
      <div className="flex flex-row gap-x-3">
        {tags &&
          tags.map((tag: string) => {
            return (
              <Link
                to={`/tags/${tag}`}
                key={tag}
                className="text-sm text-primary font-semibold hover:text-[#12cf7e] py-1 rounded-md"
              >
                {tag}
              </Link>
            )
          })}
      </div>
    </div>
  )
}

export default FeaturedBlogCard
