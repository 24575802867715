import * as React from 'react'

import { Link } from 'gatsby'

interface IPropTypes {
  title: string
  slug: string
  subtitle: string
  description: string
}

const FeaturedProjectCard = ({
  title,
  slug,
  subtitle,
  description,
}: IPropTypes) => {
  return (
    <div className="h-full full transition ease-in-out hover:-translate-y-1 border border-4 border-[#705ef2] bg-secondary p-4 mt-4 rounded-lg">
      <Link to={`/projects/${slug}`}>
        <h4 className="text-primary text-2xl font-bold">{title}</h4>
        <div className="flex flex-row gap-x-4">
          <p className="text-sm text-primary my-1">{subtitle}</p>
        </div>
        <p className="text-primary opacity-60 mt-2">{description}</p>
      </Link>
    </div>
  )
}

export default FeaturedProjectCard
