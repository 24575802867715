import * as React from 'react'

import { useThemeContext } from '../../context/theme-context'

// TODO: need to add a confirmation modal here
const EmailCTA = () => {
  const { theme } = useThemeContext()
  return (
    // <div className="h-full w-full  my-4">
    <div className="mx-auto max-w-3xl mt-12 px-6">
      <div className="bg-gradient-to-r from-[#12cf7e] to-[#705ef2] rounded-md p-1">
        <div className="flex flex-col bg-secondary border border-4 border-tertiary p-4 gap-y-2 rounded-md">
          <h3 className="font-bold text-2xl mb-2">
            Join the list. No spam. Unsubscribe at any time.
          </h3>
          <p className="text-primary opacity-60 mb-2">
            The goal of my newsletter is to share content about front-end, what
            it's like to work in engineering, information visualization and
            London. If this sounds like something you'd like I'll let you know
            when I publish new posts and also share exclusive content only for
            those on the list!
          </p>
          <form className="flex flex-wrap gap-x-2 gap-y-2">
            <input
              className={`${
                theme === 'theme-dark' ? 'bg-primary' : 'bg-white'
              } flex-grow px-2 py-2 rounded-md border border-2 border-secondary inset-shadow`}
              type="text"
              placeholder="Email"
            />
            <input
              className="flex-grow border-2 border-[#12cf7e] hover:border-accent px-4 py-2 rounded-md font-bold cursor-pointer"
              type="submit"
            ></input>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmailCTA
