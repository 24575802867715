import * as React from 'react'

import { graphql, StaticQuery, Link } from 'gatsby'
import { ArrowSmRightIcon } from '@heroicons/react/outline'

import FeaturedBlogCard from '../Cards/Featured/FeaturedBlogCard'

const LatestPosts = () => {
  return (
    <div className="mx-auto max-w-3xl mt-12 px-6">
      <h3 className="font-black text-3xl text-primary mb-4">Featured Posts</h3>
      <div className="grid grid-cols-12 lg:gap-x-6">
        <StaticQuery
          query={graphql`
            query LatestPostsQuery {
              allMdx(
                sort: { fields: [frontmatter___title], order: ASC }
                filter: { frontmatter: { category: { eq: "blog" } } }
                limit: 3
              ) {
                nodes {
                  slug
                  frontmatter {
                    title
                    description
                    tags
                  }
                }
              }
            }
          `}
          render={(data) =>
            data.allMdx.nodes.map((post: any) => {
              return (
                <div key={post.slug} className="lg:mx-0 col-span-12">
                  <FeaturedBlogCard
                    title={post.frontmatter.title}
                    slug={post.slug}
                    description={post.frontmatter.description}
                    tags={post.frontmatter.tags}
                  />
                </div>
              )
            })
          }
        />
      </div>
      <div className="col-span-12 mt-4 mb-18">
        <Link
          className="font-semibold special-underline inline-block gap-x-2"
          to="/blog"
        >
          Read all Posts
          <ArrowSmRightIcon className="inline-block text-primary h-5 w-5 my-2" />
        </Link>
      </div>
    </div>
  )
}

export default LatestPosts
