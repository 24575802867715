import * as React from 'react'

import { Link } from 'gatsby'

import Layout from '../components/core/Layout'
import EmailCTA from '../components/EmailCTA/EmailCTA'
import FeaturedPosts from '../components/Sections/FeaturedPosts'
import FeaturedProjects from '../components/Sections/FeaturedProjects'
import Emoji from '../components/Emojis/Emoji'

const Index = () => {
  return (
    <Layout
      title="Yannis Panagis"
      description="I’m a Fullstack Engineer at Behaviour Lab and lead the front-end team
      there. I studied Electrical and Electronic Engineering at Imperial College London"
      aurora
    >
      <div className="mt-12 px-6">
        <h6 className="text-xl">
          Hi, I'm <Emoji symbol="👋" label="wave" />
        </h6>
        <h1 className="text-8xl font-bold ml-0 mt-4 pl-0">Yannis Panagis</h1>
        <p className="max-w-3xl mt-4 text-lg antialiased">
          I’m a Fullstack Engineer at Behaviour Lab and lead the front-end team
          there. I studied Electrical and Electronic Engineering at{' '}
          <span className="font-bold">Imperial College London</span>. I spend my
          time <span className="font-bold">building products</span>,{' '}
          <span className="font-bold">visualizing data</span>,{' '}
          <span className="font-bold">exploring London</span>, and{' '}
          <span className="font-bold">writing music</span>.
        </p>

        <div className="flex flex-row mt-4 mb-20 space-x-4">
          <Link
            className="border-2 border-[#12cf7e] hover:border-accent px-8 py-2 rounded-md font-bold"
            to="/about"
          >
            About
          </Link>
        </div>
      </div>
      <FeaturedPosts />
      <FeaturedProjects />
      <EmailCTA />
    </Layout>
  )
}

export default Index
