import * as React from 'react'

import { graphql, StaticQuery, Link } from 'gatsby'
import { ArrowSmRightIcon } from '@heroicons/react/outline'

import FeaturedProjectCard from '../Cards/Featured/FeaturedProjectCard'

const LatestProjects = () => {
  return (
    <div className="mx-auto max-w-3xl mt-12 px-6">
      <h3 className="font-black text-3xl text-primary">Featured Projects</h3>
      <div className="grid grid-cols-12 gap-4 w-full mb-10">
        <StaticQuery
          query={graphql`
            query LatestProjectsQuery {
              allMdx(
                filter: {
                  frontmatter: {
                    category: { eq: "projects" }
                    featured: { eq: true }
                  }
                }
                limit: 2
              ) {
                nodes {
                  slug
                  frontmatter {
                    title
                    subtitle
                    description
                  }
                }
              }
            }
          `}
          render={(data) =>
            data.allMdx.nodes.map((post: any) => {
              return (
                <div
                  key={post.slug}
                  className="lg:mx-0 col-span-12 lg:col-span-6 h-full"
                >
                  <FeaturedProjectCard
                    title={post.frontmatter.title}
                    slug={post.slug}
                    subtitle={post.frontmatter.subtitle}
                    description={post.frontmatter.description}
                  />
                </div>
              )
            })
          }
        />
      </div>
      <div className="col-span-12 mb-18">
        <Link
          className="font-semibold special-underline inline-block gap-x-2"
          to="/projects"
        >
          Read all Projects
          <ArrowSmRightIcon className="inline-block text-primary h-5 w-5 my-2" />
        </Link>
      </div>
    </div>
  )
}

export default LatestProjects
